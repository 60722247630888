import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_PICKUP_REQUEST } from "constants/PickUpRequest";
import { fetchPickupRequestFailure, fetchPickupRequestSuccess } from "redux/actions";


import PickupRequest from "services/PickupRequest";

export function* onFetchPickupRequest() {
  yield takeLatest(FETCH_PICKUP_REQUEST, function* (payload) {
    try {
      const result = yield call(PickupRequest.get, payload.payload);
      if (result.status) {
        yield put(fetchPickupRequestSuccess(result.data));
      } else {
        yield put(fetchPickupRequestFailure(result.message));
      }
    } catch (error) {
        message.error("something went wrong");
      yield put(fetchPickupRequestFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchPickupRequest)]);
}
