import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { ROLE_GUEST, UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_LOCATION_LOOKUP_REQUEST,
  FETCH_ZONE_REQUEST,
  FETCH_WOREDA_REQUEST,
  FETCH_NEW_ORDER_TESTS_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
  UPDATE_NEW_ORDER_REQUEST,
  CREATE_NEW_ORDER_REQUEST
} from "constants/NewOrder";
import {
  fetchNewOrderTestSuccess,
  fetchNewOrderTestFailure,
  fetchLocationLookUpSuccess,
  fetchLocationLookUpFailure,
  setNewOrderValidationErrors,
  fetchNewOrderPatientsSuccess,
  fetchNewOrderPatientsFailure,
  updateNewOrderSuccess,
  updateNewOrderFailure,
  createNewOrderSuccess,
  createNewOrderFailure,
  fetchzoneSuccess,
  fetchzoneFailure,
  fetchworedaSuccess,
  fetchworedaFailure,
} from "redux/actions";

import NewOrderService from "services/NewOrder";
import { getCurrentRole } from "utils/CurrentUserUtil";
import { openPdf, printPdf } from "views/admin-views/neworder/openPatientOrderSummary";
import {handleLogisPickUp} from "../../utils/LogisPickup";
import { sendSampleToSampleManagement } from "utils/SampleManagement";

export function* onFetchNewOrderTest() {
  yield takeLatest(FETCH_NEW_ORDER_TESTS_REQUEST, function* () {
    try {
      const result = yield call(NewOrderService.index);
      if (result.status) {
        yield put(fetchNewOrderTestSuccess(result.data));
      } else {
        yield put(fetchNewOrderTestFailure(result.message));
      }
    } catch (error) {
      yield put(fetchNewOrderTestFailure(error.message));
    }
  });
}

export function* onFetchLocationLookUp() {
  yield takeLatest(FETCH_LOCATION_LOOKUP_REQUEST, function* () {
    try {
      const result = yield call(NewOrderService.getLocations);
      if (result.status) {
        yield put(fetchLocationLookUpSuccess(result.data));
      } else {
        yield put(fetchLocationLookUpFailure(result.message));
      }
    } catch (error) {
      yield put(fetchLocationLookUpFailure(error.message));
    }
  });
}

export function* onFetchzone() {
  yield takeLatest(FETCH_ZONE_REQUEST, function* (parentid ) {
    try {
      const result = yield call(NewOrderService.getzone,parentid);
      if (result.status) {
        yield put(fetchzoneSuccess(result.data));
      } else {
        yield put(fetchzoneFailure(result.message));
      }
    } catch (error) {
      yield put(fetchzoneFailure(error.message));
    }
  });
}

export function* onFetchworeda() {
  yield takeLatest(FETCH_WOREDA_REQUEST, function* (parentid ) {
    try {
      const result = yield call(NewOrderService.getzone,parentid);
      if (result.status) {
        yield put(fetchworedaSuccess(result.data));
      } else {
        yield put(fetchworedaFailure(result.message));
      }
    } catch (error) {
      yield put(fetchworedaFailure(error.message));
    }
  });
}

export function* onFetchExistingPatients() {
  yield takeLatest(FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST, function* ({payload}) {
    try {
      const result = yield call(NewOrderService.searchpatient,payload);
      if (result.status  & result.data.length >= 0) {
        yield fetchNewOrderPatientsSuccess(result.data);
      } else {
        message.error("Error Fetching Patient");
        yield put(fetchNewOrderPatientsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchNewOrderPatientsFailure(error.message));
    }
  });
}

export function* onCreateNewOrder () {
  yield takeLatest(CREATE_NEW_ORDER_REQUEST, function* ({payload} ) {
    try {
      const result = yield call(NewOrderService.create, payload.newOrder);
      if (result.status && getCurrentRole() != ROLE_GUEST) {
        let orderInformation = result.data;
        let sampleId = orderInformation.visitInfo.webSampleId;
        payload.sampleManagementData.sample_ID = sampleId;
        handleLogisPickUp();
        // console.log(payload.sampleManagementData);
        sendSampleToSampleManagement(payload.sampleManagementData);
        yield put(createNewOrderSuccess(result.data));
        openPdf(sampleId, payload.doesThePdfAllowedToBeOpened);
      } else if(result.status && getCurrentRole() === ROLE_GUEST){
        yield put(createNewOrderSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setNewOrderValidationErrors(result.data.errors));
        yield put(createNewOrderFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createNewOrderFailure(result.message));
      }

    } catch (error) {
      message.error("Something went wrong");
      yield put(createNewOrderFailure(error.message));
    }
  });
}


export function* onUpdateNewOrder() {
  yield takeLatest(UPDATE_NEW_ORDER_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        NewOrderService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateNewOrderSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setNewOrderValidationErrors(result.data.errors));
        yield put(updateNewOrderFailure(result.message));
      } else {
        yield put(updateNewOrderFailure(result.message));
      }
    } catch (error) {
      yield put(updateNewOrderFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchzone),
    fork(onFetchworeda),
    fork(onFetchNewOrderTest),
    fork(onFetchLocationLookUp),
    fork(onFetchExistingPatients),
    fork(onCreateNewOrder),
    fork(onUpdateNewOrder)
  ]);
}
