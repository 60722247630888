import{
    FETCH_TB_MICRO_RESULTS_REQUEST,
    FETCH_TB_MICRO_RESULTS_SUCCESS,
    FETCH_TB_MICRO_RESULTS_FAILURE
} from "constants/TB_Micro";

export const FetchTbMicroRequest = (startDate, endDate) =>{
    return{
        type:FETCH_TB_MICRO_RESULTS_REQUEST,
        payload:{
        startDate,
        endDate
        }
    }
}

export const FetchTbMicroSuccess = (payload) =>{
    return{
        type:FETCH_TB_MICRO_RESULTS_SUCCESS,
        payload:payload
        }
}

export const FetchTbMicroFailure = (payload) =>{
    return{
        type:FETCH_TB_MICRO_RESULTS_FAILURE,
        payload:payload
    }
}