import fetch from "auth/FetchInterceptor";

const CoreLabResultsServices = {};

CoreLabResultsServices.get = function (payload) {
  return fetch({
    url:
      "/getTVScreenPendingResult?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "resultType=" + "CoreLab"+ "&department=null",
    method: "get",
  });
};

export default CoreLabResultsServices;
