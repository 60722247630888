import{
    UPLOAD_CSV_FILE_REQUEST,
    UPLOAD_CSV_FILE_FAILURE,
    UPLOAD_CSV_FILE_SUCCCESS
} from "constants/UploadCsvFiles"

export const onUploadCsvFileRequest = (formData) =>{
    return {
       type : UPLOAD_CSV_FILE_REQUEST,
       payload : {
        formData,
      }
    }
}


export const onUploadCsvFileSuccess = (payload) =>{
   return {
      type : UPLOAD_CSV_FILE_SUCCCESS,
      payload : payload
   }
}


export const onUploadCsvFileFailure = (errorMessage) =>{
   return {
      type : UPLOAD_CSV_FILE_FAILURE,
      paylaod : errorMessage
   }
}