import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UPLOAD_CSV_FILE_REQUEST} from "constants/UploadCsvFiles"; 
import { onUploadCsvFileSuccess, onUploadCsvFileFailure } from "redux/actions";
import UploadCsvFileService from "services/UploadCsvFile";

export function* onUploadCsvFile() {
    yield takeLatest(UPLOAD_CSV_FILE_REQUEST, function* (payload) {
      try {
       const result = yield call(UploadCsvFileService.upload, payload.payload);
        if (result.status) {
          message.success("Uploaded Successfully");
          yield put(onUploadCsvFileSuccess(result.data));
        } else {
            message.error("unable to upload the file");
          yield put(onUploadCsvFileFailure(result.message));
        }
      } catch (error) {
        message.error("unexpected error occured");
        yield put(onUploadCsvFileFailure(error.message));
      }
    });
  }

export default function* rootSaga() {
    yield all([
      fork(onUploadCsvFile),
    ]);
  }
  