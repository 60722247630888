import{
    FETCH_PATHOLOGY_RESULT_FAILURE,
    FETCH_PATHOLOGY_RESULT_REQUEST,
    FETCH_PATHOLOGY_RESULT_SUCCESS
} from "constants/Pathology"
export const fetchPathologyResultRequest = (startDate, endDate) =>{
    return{
        type : FETCH_PATHOLOGY_RESULT_REQUEST,
        payload : {
            startDate, 
            endDate
       }
    } 
}   

export const fetchPathologyResultSuccess = (payload)=>{
    return {
        type : FETCH_PATHOLOGY_RESULT_SUCCESS,
        payload : payload
    }
}

export const fetchPathologyResultFailure = (payload) =>{
    return {
        type : FETCH_PATHOLOGY_RESULT_FAILURE,
        payload : payload
    }
}
 