import fetch from "auth/FetchInterceptor";

const UploadCsvFileService = {};

UploadCsvFileService.upload = function (payload) {
  return fetch({
    url: "/upload-csv",
    method: "post",
    headers: { "Content-Type": "multipart/form-data"},
    data : payload.formData
  });
};

export default UploadCsvFileService;