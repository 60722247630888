import { ROLE_GUEST } from "configs/AppConfig";
import {
  SET_NEW_ORDER_VALIDATION_ERRORS,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS,
  FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE,
  FETCH_NEW_ORDER_TESTS_REQUEST,
  FETCH_NEW_ORDER_TESTS_FAILURE,
  FETCH_NEW_ORDER_TESTS_SUCCESS,
  FETCH_LOCATION_LOOKUP_REQUEST,
  FETCH_LOCATION_LOOKUP_FAILURE,
  FETCH_LOCATION_LOOKUP_SUCCESS,
  FETCH_ZONE_REQUEST,
  FETCH_ZONE_FAILURE,
  FETCH_ZONE_SUCCESS,
  FETCH_WOREDA_REQUEST,
  FETCH_WOREDA_FAILURE,
  FETCH_WOREDA_SUCCESS,
  SHOW_NEW_ORDER_SEARCH_FORM,
  UPDATE_NEW_ORDER_FAILURE,
  UPDATE_NEW_ORDER_REQUEST,
  UPDATE_NEW_ORDER_SUCCESS,
  CREATE_NEW_ORDER_SUCCESS,
  CREATE_NEW_ORDER_REQUEST,
  CREATE_NEW_ORDER_FAILURE,
  CLOSE_PAYMENT_DRAWER,
  SHOW_PAYMENT_DRAWER,
} from "constants/NewOrder";
import { getCurrentRole } from "utils/CurrentUserUtil";

import {
  addNewOrder,
  formtValidationErrors,
  updatedTestOrder
} from "utils/NewOrder";

const initState = {
  loading: false,
  updating:false,
  creating : false,
  list: [],
  newOrderResults : [],
  patientslist: [],
  errorMessage: "",
  validationErrors: [],
  showSearchForm:false,
  showPaymentDrawer : false,
  patientData:"",
  locations:[],
  zones:[],
  woredas:[],
  initialpatient:null,
};

const newOrder = (state = initState, action) => {
  switch (action.type) {
    case FETCH_NEW_ORDER_TESTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NEW_ORDER_TESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_NEW_ORDER_TESTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };


      case FETCH_LOCATION_LOOKUP_REQUEST:
        return {
          ...state,
          loading: true,
          // showSearchForm:true,
        };
      case FETCH_LOCATION_LOOKUP_SUCCESS:
        return {
          ...state,
          loading: false,
          locations: action.payload,
        };
      case FETCH_LOCATION_LOOKUP_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };


        case FETCH_ZONE_REQUEST:
          return {
            ...state,
            // loading: true,
            
          };
        case FETCH_ZONE_SUCCESS:
          return {
            ...state,
            loading: false,
            zones: action.payload,
          };
        case FETCH_ZONE_FAILURE:
          return {
            ...state,
            loading: false,
            errorMessage: action.payload,
          };

          case FETCH_WOREDA_REQUEST:
            return {
              ...state,
              // loading: true,
              
            };
          case FETCH_WOREDA_SUCCESS:
            return {
              ...state,
              loading: false,
              woredas: action.payload,
            };
          case FETCH_WOREDA_FAILURE:
            return {
              ...state,
              loading: false,
              errorMessage: action.payload,
            };

      //existing patients
      case FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          patientslist: action.payload,
        };
      case FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };

      //end existing patients

    case SET_NEW_ORDER_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
   
      case   SHOW_NEW_ORDER_SEARCH_FORM:
        return {
          ...state,
          showSearchForm: !state.showSearchForm,
        };

        case CREATE_NEW_ORDER_REQUEST:
        return {
          ...state,
          creating: true,
        };
        case CREATE_NEW_ORDER_SUCCESS:
          return {
            ...state,
            creating: false,
            newOrderResults : action.payload,
            showPaymentDrawer : getCurrentRole() === ROLE_GUEST ? true : false
          };
        case CREATE_NEW_ORDER_FAILURE:
          return {
            ...state,
            creating: false,
            errorMessage: action.payload,
          };

        case UPDATE_NEW_ORDER_REQUEST:
          return {
            ...state,
            updating: true,
            errorMessage: "",
            validationErrors: [],
          };
        case UPDATE_NEW_ORDER_SUCCESS:
          return {
            ...state,
            updating: false,
            list: updatedTestOrder(state, action),
            errorMessage: "",
            validationErrors: [],
            showSearchForm: false,
          };
        case UPDATE_NEW_ORDER_FAILURE:
          return {
            ...state,
            updating: false,
            errorMessage: action.payload,
            validationErrors: [],
          };
        case SHOW_PAYMENT_DRAWER : 
        return {
           ...state, 
           showPaymentDrawer : true
        }

        case CLOSE_PAYMENT_DRAWER : 
        return {
           ...state, 
           showPaymentDrawer : false
        }
  
    default:
      return state;
  }
};

export default newOrder;
