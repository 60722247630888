import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Switch } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavSearch  from './NavSearch';
import { toggleCollapsedNav, onMobileNavToggle,onSwitchTheme } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils'
import NavNotification from "./NavNotification";
import { NavProfile } from "./NavProfile";
import { signOut } from "redux/actions/Auth";
import { currentUser } from "auth/FirebaseAuth";

const { Header } = Layout;

export const HeaderNav = props => {
  const { currentUser, onSwitchTheme, signOut, navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction } = props;
  const [searchActive, setSearchActive] = useState(false)
  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if(!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = ()=> {
    if(!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff' )
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if(isNavTop || isMobile) {
      return '0px'
    }
    if(navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {
    if(!isMobile) {
      onSearchClose()
    }
  })

  function handleTheme(event){
    if(event == 1){
      let currentTheme = "dark";
      onSwitchTheme(currentTheme);
      localStorage.setItem("darkMode", "dark");
    } else {
      let currentTheme = "light";
      localStorage.setItem("darkMode", "light");
      onSwitchTheme(currentTheme);
    }
  }
  return (
    <Header className={`app-header ${navMode}`} style={{backgroundColor: headerNavColor}}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode}/>
        <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">          
              {
                isNavTop && !isMobile ?
                null
                :
                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onToggle()}}>
                  {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                </li>
              }
            </ul>
          </div>
         
          <div className="nav-right">
          <Switch checked={currentTheme == "dark" ? 1 : 0} checkedChildren="dark" unCheckedChildren="light" onChange={(event) => handleTheme(event)} title="Dark Mode" style={{marginTop : "25px", marginRight : "30px"}}></Switch> 
           <NavNotification />
           <NavProfile signOut={signOut} currentUser={currentUser}/>

            {/* <NavPanel direction={direction} /> */}
          </div>
          <NavSearch active={searchActive} close={onSearchClose}/>
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { currentUser } = auth
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } =  theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, currentUser, }
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle, signOut, onSwitchTheme})(HeaderNav);