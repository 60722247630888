import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_COMPLETED_RESULTS_REQUEST } from "constants/CompletedResults";
import { 
    fetchCompletedResultFailure,
    fetchCompletedResultSuccess
} from "redux/actions/CompletedResults"

import CompletedResultsService from "services/CompletedResults";

export function* onFetchCompletedResults() {
  yield takeLatest(FETCH_COMPLETED_RESULTS_REQUEST, function* (payload) {
    try {
      const result = yield call(CompletedResultsService.get, payload.payload);
      if (result.status) {
        yield put(fetchCompletedResultSuccess(result.data));
      } else {
        yield put(fetchCompletedResultFailure(result.message));
      }
    } catch (error) {
      yield put(fetchCompletedResultFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchCompletedResults)]);
}
