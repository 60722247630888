import {
  FETCH_SAMPLE_ACCEPTED_REQUEST,
  FETCH_SAMPLE_ACCEPTED_SUCCESS,
  FETCH_SAMPLE_ACCEPTED_FAILURE,
} from "../../constants/SampleAccepted";

const initialState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const OMBGUSampleNotRecievedByICL = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SAMPLE_ACCEPTED_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_SAMPLE_ACCEPTED_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };

    case FETCH_SAMPLE_ACCEPTED_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return state
    }
  }

export default OMBGUSampleNotRecievedByICL;
