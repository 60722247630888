import fetch from "auth/FetchInterceptor";

const TbMicroResultsService = {};

TbMicroResultsService.get = function (payload) {
    return fetch({
      url:
        "/getTVScreenPendingResult?" +
        "patientid=" +
        payload.startDate +
        "&" +
        "endDate=" +
        payload.endDate +
        "&" + 
        "resultType=" + "TbMicro" + "&department=null",
        method: "get",
    });
  };

  
export default TbMicroResultsService;
