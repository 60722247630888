import fetch from "auth/FetchInterceptor";

const UploadFileService = {};

UploadFileService.upload = function (payload) {
  return fetch({
    url: "/uploadFinancialReports",
    method: "post",
    headers: { "Content-Type": "multipart/form-data"},
    data : payload
  });
};

export default UploadFileService;