// TestDirectories Permission
export const VIEW_TESTDIRECTORIES = "view-testDirectories";
export const VIEW_DISCONTINUED_TEST_DIRECTORIES =
  "view-discontinued-test-directories";
export const CREATE_TESTDIRECTORIES = "create-testDirectories";
export const UPDATE_TESTDIRECTORIES = "update-testDirectories";
export const DELETE_TESTDIRECTORIES = "delete-testDirectories";

// Departments Permissions
export const VIEW_DEPARTMENTS = "view-departments";
export const CREATE_DEPARTMENTS = "create-departments";
export const UPDATE_DEPARTMENTS = "update-departments";
export const DELETE_DEPARTMENTS = "delete-departments";

// Patients Permissions
export const VIEW_PATIENTS = "view-patients";
export const CREATE_PATIENTS = "create-patients";
export const UPDATE_PATIENTS = "update-patients";
export const DELETE_PATIENTS = "delete-patients";

//Agency test association form permission
export const VIEW_AGENCY_TEST_ASSOCIATION_FORM =
  "view-agency-test-association-form";

//Discrepanies Permissions
export const VIEW_ALL_DISCREPANCIES = "view-all-discrepancies";
export const VIEW_PENDING_DISCREPANCIES = "view-pending-discrepancies";
export const VIEW_APPROVED_DESCREPANCIES = "view-approved-descrepancies";
export const VIEW_DECLINED_DESCREPANCIES = "view-declined-descrepancies";
//permissions for the fault result
export const VIEW_FAULT_RESULTS = "view-fault-results";

//Discrepancies Modificaton Permissions
export const APPROVE_DISCREPANCY = "approve-discrepancy";
export const DECLINE_DISCREPANCY = "decline-discrepancy";
export const COMMUNICATE_DISCREPANY = "communicate-discrepancy";

//Micro biology results permission
export const VIEW_MICRO_BIOLOGY_RESULTS = "view-micro-biology-results";
export const UPLOAD_MICRO_BIOLOGIES_RESULTS = "upload-micro-biology-results";
export const UPLOAD_CSV_PANELS = "upload-csv-panels";
export const UPLOAD_FINANCIAL_REPORT = "upload-financial-report";


//Order Permissions
export const VIEW_ALL_ORDERS = "view-all-orders";
export const VIEW_APPROVED_ORDERS = "view-approved-orders";
export const VIEW_PENDING_ORDERS = "view-pending-orders";
export const VIEW_DELCINED_ORDERS = "view-declined-orders";
export const VIEW_STAT_ORDERS = "view-stat-orders";
export const APPROVE_WEB_ORDER = "approve-web-order";
export const DECLINE_WEB_ORDERS = "decline-web-order";
export const VIEW_REFERABLE_ORDERS = "view-referable-orders";
export const VIEW_TEST_OBX_PER_TECHNICIAN = "view-test-obx-per-technician";
export const VIEW_NOT_RELEASED_ORDERS = "view-not-released-orders";
export const VIEW_SAMPLE_NOT_RECIEVED_ORDERS =
  "view-sample-not-recieved-orders";
export const VIEW_SAMPLE_IN_PROCESS = "view-sample-in-process";
export const VIEW_ORDER_STAT_RESULTS = "view-order-stat-results";
export const VIEW_PANIC_STAT_RESULTS = "view-panic-stat-results";
export const VIEW_RELEASED_PANEL_FOR_ICL = "view-released-panel-for-icl";

//Released Results Permissions
export const VIEW_ALL_RELEASED_RESULTS = "view-all-results";
export const VIEW_COMPLETED_RESULTS = "view-completed-results";
export const VIEW_ALL_INCOMPLETE_RESULTS = "view-all-incomplete-results";
export const VIEW_RESULTS_FOR_GUEST_USERS = "view-results-for-guest-users";
export const VIEW_PANIC_AND_ABNORMAL_TESTS = "view-panic-and-abnormal-tests";
export const VIEW_INPERSON_RESULTS = "view-inperson-results";
export const VIEW_PATIENT_RESULT_HISTORY = "view-patient-result-history";

// GeneralLookUps Permissions
export const VIEW_GENERALLOOKUPS = "view-generallookups";
export const CREATE_GENERALLOOKUPS = "create-generallookups";
export const UPDATE_GENERALLOOKUPS = "update-generallookups";
export const DELETE_GENERALLOOKUPS = "delete-generallookups";

// TestRegistrations Permissions
export const VIEW_TEST_REGISTRATIONS = "view-test-registrations";
export const CREATE_TEST_REGISTRATIONS = "create-test-registrations";
export const UPDATE_TEST_REGISTRATIONS = "update-test-registrations";
export const DELETE_TEST_REGISTRATIONS = "delete-test-registrations";

// ClientCompanies Permissions
export const VIEW_CLIENTCOMPANIES = "view-clientCompanies";
export const CREATE_CLIENTCOMPANIES = "create-clientCompanies";
export const UPDATE_CLIENTCOMPANIES = "update-clientCompanies";
export const DELETE_CLIENTCOMPANIES = "delete-clientCompanies";

// BranchLookUp Permissions
export const VIEW_BRANCHLOOKUPS = "view-branchlookups";
export const CREATE_BRANCHLOOKUPS = "create-branchLookups";
export const UPDATE_BRANCHLOOKUPS = "update-branchLookups";
export const DELETE_BRANCHLOOKUPS = "delete-branchLookups";

// User Permissions
export const VIEW_USERS = "view-users";
export const CREATE_USERS = "create-users";
export const UPDATE_USERS = "update-users";
export const DELETE_USERS = "delete-users";

// Coupon Permissions
export const VIEW_COUPONS = "view-coupons";
export const CREATE_COUPONS = "create-coupons";
export const UPDATE_COUPONS = "update-coupons";
export const DELETE_COUPONS = "delete-coupons";

// Section Permissions
export const VIEW_QC_SECTIONS = "view-qc-sections";
export const CREATE_QC_SECTIONS = "create-qc-sections";
export const UPDATE_QC_SECTIONS = "update-qc-sections";
export const DELETE_QC_SECTIONS = "delete-qc-sections";

// Machine Permissions
export const VIEW_QC_MACHINES = "view-qc-machines";
export const CREATE_QC_MACHINES = "create-qc-machines";
export const UPDATE_QC_MACHINES = "update-qc-machines";
export const DELETE_QC_MACHINES = "delete-qc-machines";

// Equipment Permissions
export const VIEW_QC_EQUIPMENT = "view-qc-equipment";
export const CREATE_QC_EQUIPMENT = "create-qc-equipment";
export const UPDATE_QC_EQUIPMENT = "update-qc-equipment";
export const DELETE_QC_EQUIPMENT = "delete-qc-equipment";

// CheckList Permissions
export const VIEW_QC_CHECKLIST = "view-qc-checklist";
export const CREATE_QC_CHECKLIST = "create-qc-checkList";
export const UPDATE_QC_CHECKLIST = "update-qc-checkList";
export const DELETE_QC_CHECKLIST = "delete-qc-checkList";

// Maintenance Permissions
export const VIEW_QC_MAINTENANCES = "view-qc-maintenances";
export const CREATE_QC_MAINTENANCES = "create-qc-maintenances";
export const DELETE_QC_MAINTENANCES = "delete-qc-maintenances";

// BranchTestCapacity Permissions
export const VIEW_BRANCH_TEST_CAPACITIES = "view-branch-test-capacities";
export const CREATE_BRANCH_TEST_CAPACITIES = "create-branch-test-capacities";
export const UPDATE_BRANCH_TEST_CAPACITIES = "update-branch-test-capacities";
export const DELETE_BRANCH_TEST_CAPACITIES = "delete-branch-test-capacities";

//Top Branchs for walkin customers
export const VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS =
  "view_top_branch_for_walkin_customers";

//Top Agencies
export const VIEW_TOP_AGENCIES = "view_top_agencies";

//RELEASED AFTER TAT
export const VIEW_RELEASED_AFTER_TAT = "view-released-after-tat";

//Pending Results Obx
export const VIEW_PENDING_RESULTS_OBX = "view-pending-results-obx";

//Top Tests
export const VIEW_TOP_TESTS = "view_top_tests";

export const VIEW_TOP_PANELS = "view-top-panels";

//Pending Tests
export const VIEW_PENDING_RESULTS = "view_pending_results";

//Dashboard
export const VIEW_DASHBOARD = "view_dashboard";

//Reports
export const VIEW_REPORTS = "view_reports";

//Financial
export const VIEW_FINANCIAL = "view_financial";

export const VIEW_TAT_APPROACHING = "view_tat_approaching";

// Patient PID Permissions
export const VIEW_PATIENT_PID = "view-patient-pid";
export const CREATE_PATIENT_PID = "create-patient-pid";
export const UPDATE_PATIENT_PID = "update-patient-pid";
export const DELETE_PATIENT_PID = "delete-patient-pid";

// Order abn Permissions
export const VIEW_ORDER_ABN = "view-order-abn";
export const CREATE_ORDER_ABN = "create-order-abn";
export const UPDATE_ORDER_ABN = "update-order-abn";
export const DELETE_ORDER_ABN = "delete-order-abn";

// Comment nte Permissions
export const VIEW_COMMENT_NTE = "view-comment-nte";
export const CREATE_COMMENT_NTE = "create-comment-nte";
export const UPDATE_COMMENT_NTE = "update-comment-nte";
export const DELETE_COMMENT_NTE = "delete-comment-nte";

// Panel obr Permissions
export const VIEW_PANEL_OBR = "view-panel-obr";
export const CREATE_PANEL_OBR = "create-panel-obr";
export const UPDATE_PANEL_OBR = "update-panel-obr";
export const DELETE_PANEL_OBR = "delete-panel-obr";

// Test obx Permissions
export const VIEW_TEST_OBX = "view-test-obx";
export const CREATE_TEST_OBX = "create-test-obx";
export const UPDATE_TEST_OBX = "update-test-obx";
export const DELETE_TEST_OBX = "delete-test-obx";

// New order Permissions
export const VIEW_NEWORDERS = "view-neworders";
export const CREATE_NEWORDERS = "create-neworders";
export const UPDATE_NEWORDERS = "update_neworders";
export const DELETE_NEWORDERS = "delete-neworders";

//permissions for daily released tests
export const DAILY_RELEASED_TESTS = "view-daily-released-tests";

//permissions for daily released tests
export const DAILY_SINGLE_RELEASED_TESTS = "view-daily-single-released-tests";

//permissions for daily released tests
export const DAILY_DEPARTMENT_RELEASED_TESTS =
  "view-daily-department-released-tests";

// New order Permissions
export const VIEW_PATIENTSEARCH = "view-patientsearch";

//Get locations for new order Permissions
export const VIEW_LOCATIONLOOKUPS = "view-locationlookups";

//Icl Reports  Permissions
export const PERMISSION_CREATE_ICLREPORTS = "permission:create-iclreports";
export const PERMISSION_VIEW_ICLREPORTS = "permission:view-iclreports";
export const PERMISSION_UPDATE_ICLREPORTS = "permission:update-iclreports";
export const PERMISSION_DELETE_ICLREPORTS = "permission:delete-iclreports";

//Get vwpolytechconfigpanelobrs for new order Permissions
export const VIEW_VWPOLYTECHCONFIGPANELOBRS = "view-vwpolytechconfigpanelobrs";

// Role Permissions
export const VIEW_ROLES = "view-roles";
export const UPDATE_ROLES = "update-roles";
export const CREATE_ROLES = "create-roles";
//Test Permissions
export const VIEW_AGENCIES_TESTS = "view-agencies-tests";
export const CREATE_AGENCIES_TESTS = "create-agencies-tests";
export const UPDATE_AGENCIES_TESTS = "update-agencies-tests";

//Client Company Test Directories Permissions
export const VIEW_CLIENTCOMPANYTESTDIRECTORIES =
  "view-clientCompanyTestDirectories";
export const CREATE_CLIENTCOMPANYTESTDIRECTORIES =
  "create-clientCompanyTestDirectories";
export const UPDATE_CLIENTCOMPANYTESTDIRECTORIES =
  "update-clientCompanyTestDirectories";
export const DELETE_CLIENTCOMPANYTESTDIRECTORIES =
  "delete-clientCompanyTestDirectories";

//Permissions for total statistics
export const VIEW_TOTAL_TESTS = "view-total-tests";
export const VIEW_TOTAL_PANIC_AND_ABNORMAL_TESTS =
  "view-panic-abnormal-total-tests";
export const VIEW_TOTAL_COMMENTS = "view-total-comments";
export const VIEW_TOTAL_AVERAGE_TAT = "view-total-average-tat";
export const VIEW_TOTAL_AVERAGE_RELEASE_TIME =
  "view-total-average-release-time";
export const VIEW_TOTAL_DELAYED_TIME = "view-total-delay-time";
export const VIEW_TOTAL_ORDERED_PANELS = "view-total-ordered-panels";
export const VIEW_TOTAL_TOTAL_INCOME = "view-total-income";
export const VIEW_TOTAL_PATEINTS = "view-total-patients";


//Permissions for tv screen results
export const VIEW_TV_SCREEN_RESULTS = "view-tv-screen-results";
export const VIEW_FINANCIAL_REPORTS = "view-financial-reports";
