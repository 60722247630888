import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
    FETCH_ALL_ORDER_REQUEST,
    FETCH_ORDER_OBX_REQUEST,
    UPDATE_ALL_ORDER_REQUEST,
} from "constants/OrderStatus/all-orders";
import {
  fetchAllOrderFailure,
  fetchAllOrderSuccess,
  fetchOrderObxFailure,
  fetchOrderObxSuccess,
  updateAllOrdersFailure,
  updateAllOrdersSuccess,
} from "redux/actions/OrderStatus/AllOrders";

import AllOrders from "services/OrderStatus/AllOrders";
import { updateOrderStatusToOrbit } from "utils/OrbitOrderStatusUpdate";

export function* onFetchAllOrders() {
  yield takeLatest(FETCH_ALL_ORDER_REQUEST, function* (payload) {
    try {
      const result = yield call(AllOrders.get, payload.payload);
      if (result.status) {
        yield put(fetchAllOrderSuccess(result.data));
      } else {
        yield put(fetchAllOrderFailure(result.message));
      }
    } catch (error) {
      yield put(fetchAllOrderFailure(error.message));
    }
  });
}

export function* onFetchOrderObx() {
  yield takeLatest(FETCH_ORDER_OBX_REQUEST, function* (payload) {
    try {
      const result = yield call(AllOrders.getObx, payload.payload.visitUuid);
      if (result.status) {
        yield put(fetchOrderObxSuccess(result.data));
      } else {
        yield put(fetchOrderObxFailure(result.message));
      }
    } catch (error) {
      yield put(fetchOrderObxFailure(error.message));
    }
  });
}

export function* onUpdateAllOrders() {
  yield takeLatest(UPDATE_ALL_ORDER_REQUEST, function* ( payload ) {
  try {
        const result = yield call(
        AllOrders.update,
        payload.payload
      );
      if (result.status) {
        message.success("Order Status Updated");
        const data ={
          id: payload.payload[0].uuid,
          WebOrderApprovedStatus: payload.payload[0].webOrderApprovedStatus,
        }
        updateOrderStatusToOrbit(data);
        //call another network request to orbit
        // console.log("%j", payload.payload)
        yield put(updateAllOrdersSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put( updateAllOrdersFailure(result.message ));
      } else {
        yield put( updateAllOrdersFailure,(result.message ));
      }
    } catch (error) {
      message.error("Check your inputs please");
      yield put(  updateAllOrdersFailure, (error ));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchAllOrders),
    fork(onFetchOrderObx),
    fork(onUpdateAllOrders),
  ]);
}
