import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import moment from 'moment';
import {
    FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST,
} from "constants/AdminDashboardReports/DashboardReport";
import {
    fetchAdminReportsSuccess,
    fetchAdminReportsFailure,
} from "redux/actions/AdminDashboardReports/DashboardReport";

import DashboardReport from "services/AdminDashboardReports/DashboardReport";


const delay = ms => new Promise(resolve => setTimeout(resolve, ms));


export function* onFetchAdminDashboardReports() {
  yield takeLatest(FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST, function* (payload) {
    try {
      const result = 
      {"status":true,"code":200,"message":"OK","data":
        [ 
        [{ 
          "totalObxTestTatInHour":"2000080200", 
          "totalObxCount" : "2010",
          "totalObxResultDelaySinceReceived":"2000300", 
          "totalObxResultDelaySinceCollected":"2000400", 
          "walkInCustomerPatientCount":"2256465", 
          "agencyPatientCount":"226575", 
          "walkInCustomerOrderCount":"222668", 
          "agencyOrderCount":"266677", 
          "walkInCustomerOrderIncome":"225468", 
          "agencyOrderIncome":"22856", 
          "totalOrderIncome":"555985", 
          "obxObxTestCount":"2555", 
          "obxPanicCount":"25", 
          "nteCommentCount":"2395", 
          "pidAgencyCount":"200" 
        } ],
        [{ 
          "totalObxTestTatInHour":"3000080200", 
          "totalObxCount" : "3010",
          "totalObxResultDelaySinceReceived":"3000300", 
          "totalObxResultDelaySinceCollected":"3000400", 
          "walkInCustomerPatientCount":"3256465", 
          "agencyPatientCount":"326575", 
          "walkInCustomerOrderCoun0t":"322668", 
          "agencyOrderCount":"366677", 
          "walkInCustomerOrderIncome":"325468", 
          "agencyOrderIncome":"32856", 
          "totalOrderIncome":"655985", 
          "obxObxTestCount":"3555", 
          "obxPanicCount":"35", 
          "nteCommentCount":"3395", 
          "pidAgencyCount":"300" 
        } ]
      ]};
      yield call(delay, 3000);

      if (result.status) {
        yield put(fetchAdminReportsSuccess(payload.payload.startDate == moment().format("YYYYMMDD") ?  result.data[0] : result.data[1]));
      } else {
        yield put(fetchAdminReportsFailure(result.message));
      }
      
    // const result = yield call(DashboardReport.get, payload.payload);
    //   if (result.status) {
    //     yield put(fetchAdminReportsSuccess(result.data));
    //   } else {
    //     yield put(fetchAdminReportsFailure(result.message));
    //   }

    } catch (error) {
      // message.error("something went wrong");
      yield put(fetchAdminReportsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchAdminDashboardReports),
  ]);
}
