import fetch from "auth/FetchInterceptor";

const PathologyResultsService = {};

PathologyResultsService.get = function (payload) {
  return fetch({
    url:
      "/getTVScreenPendingResult?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "resultType=" + "Pathology" + "&department=null",
    method: "get",
  });
};

export default PathologyResultsService;
