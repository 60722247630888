import {
    FETCH_CORELAB_RESULTS_FAILURE,
    FETCH_CORELAB_RESULTS_REQUESET,
    FETCH_CORELAB_RESULTS_SUCCESS
} from "constants/CoreLab";

export const fetchCoreLabResultsRequests = (startDate, endDate) =>{
    return{
        type : FETCH_CORELAB_RESULTS_REQUESET,
        payload : {
            startDate, 
            endDate
       }
    } 
}   

export const fetchCoreLabResultsSuccess = (payload)=>{
    return {
        type : FETCH_CORELAB_RESULTS_SUCCESS,
        payload : payload
    }
}

export const fetchCoreLabResultsFailure = (payload) =>{
    return {
        type : FETCH_CORELAB_RESULTS_FAILURE,
        payload : payload
    }
}
 