import {
    FETCH_FINANCIAL_COST_REQUEST,
    FETCH_FINANCIAL_COST_FAILURE,
    FETCH_FINANCIAL_COST_SUCCESS
} from "constants/Cost";

const initialState = {
     loading : false,
     errorMessage : "",
     list : []
}

 const Cost = (state =initialState, action) =>{
     switch(action.type){
         case FETCH_FINANCIAL_COST_REQUEST :
            return{
                 loading: true
            }
         case FETCH_FINANCIAL_COST_SUCCESS : 
            return{
                 loading : false,
                 list : action.payload,
            }
         case FETCH_FINANCIAL_COST_FAILURE : 
            return{
                 loading : false,
                 errorMessage : action.paylaod
            }
        default : 
         return state;
     }
}
export default Cost;