import axios from 'axios'
import {SAMPLE_MANAGEMENT_EMAIL, SAMPLE_MANAGEMENT_PASSWORD, SAMPLE_MANAGEMENT_TOKEN_KEY } from 'redux/constants/Auth';
// Define the API URL you want to call.
const apiUrl = 'https://tubes.iclpartner.com/api/createtest';

const loginUrl = 'https://tubes.iclpartner.com/api/login';

// Make a POST request to the API with the payload and token.
export const sendSampleToSampleManagement= async (data)=>{
    const token = getToken();
   
    if(token){
        nowCall(data, token, apiUrl);
    } else {
        login(data, apiUrl);
    }
}

function login(data, url){
    axios({
        url: loginUrl,
        method: 'POST',
        data : {
             'email' : SAMPLE_MANAGEMENT_EMAIL,
             'password' : SAMPLE_MANAGEMENT_PASSWORD
        }
    }).then((response)=>{
        setTokenAndInitiateApiCall(data, response.data.token, url);
    })
    .catch((error)=>{
        console.log('Error', error)
    });
}

function setTokenAndInitiateApiCall(data,token, url){
    //now set the token
    localStorage.setItem(SAMPLE_MANAGEMENT_TOKEN_KEY, token);
    nowCall(data, token, url);
}

function nowCall(data, token, url){
    // console.log('%j', data);
    axios({
        url: url,
        method: 'POST',
        data : data,
        headers : {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', 
          }
    }).then((response)=>{
        console.log('respones is ok', response.data)
    })
    .catch((error)=>{
        if(error.response.status == 401){
            login(data, url);
        }
        console.log('Error', error)
    });
}

function getToken(){
    return localStorage.getItem(SAMPLE_MANAGEMENT_TOKEN_KEY);
}

