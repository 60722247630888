import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {FETCH_FINANCIAL_REPORTS_REQUEST} from "constants/FinancialReport";
import {onUploadFileSuccess, onUploadFileFailure} from "redux/actions/FinancialReports";
import FinancialReportService from "services/FinancialReportService";

export function* onUploadFile() {
    yield takeLatest(FETCH_FINANCIAL_REPORTS_REQUEST, function* (payload) {
      try {
        // console.log('%j', payload);
        const result = yield call(FinancialReportService.upload, payload.payload.formData);
        if (result.status) {
          message.success("Uploaded Successfully");
          yield put(onUploadFileSuccess(result.data));
        } else {
            message.error("unable to upload the file");
          yield put(onUploadFileFailure(result.message));
        }
      } catch (error) {
        message.error("unexpected error occured");
        yield put(onUploadFileFailure(error.message));
      }
    });
  }

export default function* rootSaga() {
    yield all([
      fork(onUploadFile),
    ]);
  }
  