import { message } from "antd";
import { env } from "configs/EnvironmentConfig";

export const openPdf =(sampleId, shouldOpenPdf)=> {
    if(shouldOpenPdf){
        var url = env.PDF_API_BASE_URL + "/order/pdf/" +  sampleId;
        var win = window.open(url, '_blank');
        win.focus();
    }
    else {
        message.success("successfully ordered");
    }
}
