export const SHOW_TEST_REGISTRATION_NEW_FORM = "SHOW_TEST_REGISTRATION_NEW_FORM";
export const SHOW_TEST_REGISTRATION_EDIT_FORM = "SHOW_TEST_REGISTRATION_EDIT_FORM";
export const SET_TEST_REGISTRATION_VALIDATION_ERRORS = "SET_TEST_REGISTRATION_VALIDATION_ERRORS";

export const FETCH_TEST_REGISTRATIONS_REQUEST = "FETCH_TEST_REGISTRATIONS_REQUEST";
export const FETCH_TEST_REGISTRATIONS_SUCCESS = "FETCH_TEST_REGISTRATIONS_SUCCESS";
export const FETCH_TEST_REGISTRATIONS_FAILURE = "FETCH_TEST_REGISTRATIONS_FAILURE";

export const UPDATE_TEST_REGISTRATION_REQUEST = "UPDATE_TEST_REGISTRATION_REQUEST";
export const UPDATE_TEST_REGISTRATION_SUCCESS = "UPDATE_TEST_REGISTRATION_SUCCESS";
export const UPDATE_TEST_REGISTRATION_FAILURE = "UPDATE_TEST_REGISTRATION_FAILURE";

export const DELETE_TEST_REGISTRATION_REQUEST = "DELETE_TEST_REGISTRATION_REQUEST";
export const DELETE_TEST_REGISTRATION_SUCCESS = "DELETE_TEST_REGISTRATION_SUCCESS";
export const DELETE_TEST_REGISTRATION_FAILURE = "DELETE_TEST_REGISTRATION_FAILURE";

export const CREATE_TEST_REGISTRATION_REQUEST = "CREATE_TEST_REGISTRATION_REQUEST";
export const CREATE_TEST_REGISTRATION_SUCCESS = "CREATE_TEST_REGISTRATION_SUCCESS";
export const CREATE_TEST_REGISTRATION_FAILURE = "CREATE_TEST_REGISTRATION_FAILURE";

