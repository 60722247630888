import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";

import {FETCH_FINANCIAL_COST_REQUEST } from "constants/Cost";
import {fetchCostSuccess, fetchFCostFailure} from "redux/actions/Cost"
import Costservice from "services/Cost";

export function* Cost() {
    yield takeLatest(FETCH_FINANCIAL_COST_REQUEST, function* (payload) {
      try {
        const result = yield call(Costservice.get, payload.payload);
        if (result.status) {
          // message.success("Uploaded Successfully");
          yield put(fetchCostSuccess(result.data));
        } else {
            // message.error("unable to upload the file");
          yield put(fetchFCostFailure(result.message));
        }
      } catch (error) {
        message.error("unexpected error occured");
        yield put(fetchFCostFailure(error.message));
      }
    });
  }

export default function* rootSaga() {
    yield all([
      fork(Cost),
    ]);
  }
  