import {
  FETCH_TOP_PANELS_REQUEST,
  FETCH_TOP_PANELS_FAILURE,
  FETCH_TOP_PANELS_SUCCESS,
} from "constants/TopPanels";

const initState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const topPanels = (state = initState, action) => {
  switch (action.type) {
    case FETCH_TOP_PANELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOP_PANELS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_TOP_PANELS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return  state
  }
};

export default topPanels;
