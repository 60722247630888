import {
    FETCH_PICKUP_REQUEST,
    FETCH_PICKUP_REQUEST_FAILURE,
    FETCH_PICKUP_REQUSET_SUCCESS
} from "constants/PickUpRequest"

export const fetchPickupRequest =(startDate, endDate,agency, department)=>{
    return {
        type : FETCH_PICKUP_REQUEST,
        payload :
         { 
            startDate,
            endDate, 
            agency,
            department
         }
    }
}

export const fetchPickupRequestSuccess = (payload) =>{
    return {
         type : FETCH_PICKUP_REQUSET_SUCCESS,
         payload : payload
    }
}


export const fetchPickupRequestFailure = (payload) =>{
    return {
         type : FETCH_PICKUP_REQUEST_FAILURE,
         payload : payload
    }
}