import {
    CURRENT_USER,
    LOGIN_EXPIRATION_TIME,
    LOGIS_AUTH_TOKEN,
    LOGIS_LOCATION_LIST, LOGIS_PASSWORD,
    LOGIS_TOKEN_EXPIRATION_TIME, LOGIS_USERNAME
} from "../redux/constants/Auth";
import moment from "moment";
import {LOGIS_TOKEN_EXPIRATION_IN_HOUR, TOKEN_EXPIRATION_TIME} from "../configs/AppConfig";
import  LogisPickUp from "services/CreateLogisPickup";
import {message} from "antd";
import {ShowNotification} from "../components/shared-components/NotificationApi/ShowNotification";
import PickupRequest from "services/PickupRequest";

export  const handleLogisPickUp = async (listOfPickUpRequestsForAgencies) => {
    const LOGIS_TOKEN = localStorage.getItem(LOGIS_AUTH_TOKEN);
    const tokenExpired = new Date().getTime() > new Date(localStorage.getItem(LOGIS_TOKEN_EXPIRATION_TIME)).getTime()  && localStorage.getItem(LOGIS_TOKEN_EXPIRATION_TIME) !== null

    if (tokenExpired || !LOGIS_TOKEN) {
        const loginResult = await login();
        if(loginResult.data.token){
            localStorage.setItem(LOGIS_AUTH_TOKEN, loginResult.data.token);
            localStorage.setItem(LOGIS_TOKEN_EXPIRATION_TIME, moment().add(LOGIS_TOKEN_EXPIRATION_IN_HOUR, 'hours').toString());
        }
    }
   return  await handlePickup(listOfPickUpRequestsForAgencies);
}

async function handlePickup(listOfPickUpRequestsForAgencies){

    var numberOfSuccessRequests = 0;
    var successPickUpRequests = [];
    //now loop through the selected requests and send the request to logis 
    for(let i = 0; i < listOfPickUpRequestsForAgencies.length; i++){
        const LOGIS_TOKEN = localStorage.getItem(LOGIS_AUTH_TOKEN);
        const locations = await getLocations(LOGIS_TOKEN);
        // console.log("locations are");
        // console.log("%j" , locations.data.locations);
        localStorage.setItem(LOGIS_LOCATION_LIST, JSON.stringify(locations.data.locations));
        const currentUserLocation = getCurrentUserLocation(listOfPickUpRequestsForAgencies[i]['webAgencyName']);
        const data = {
            "token" : LOGIS_TOKEN,
            "location" : currentUserLocation.location,
            "date" : moment().format("YYYY-MM-DDTHH:MM:SS"),
            "pickup" : true,
            "material" : false,
            "urgent" : false,
            "isprivatevisit" : false,
            "private_name" :  "",
            "private_address" :  "",
            "private_city" : "",
            "private_country" : "",
            "private_phone" : "",
            "private_mobile" : "",
            "remark" : "For testing"
        };
        try {
            const pickUpRequestResult = await createPickupRequest(data);
            if(pickUpRequestResult.status === 200){
                numberOfSuccessRequests++;
                successPickUpRequests.push( {
                    "webSampleId" : listOfPickUpRequestsForAgencies[i]['webSampleId'],
                    "Status" : "Pickup Request Sent"
                });
            }
            await delay(1000);
        }catch(Exception){
            
        }
        
    }
 
    if(numberOfSuccessRequests == listOfPickUpRequestsForAgencies.length){
        ShowNotification("success", "bottomRight", "Pickup request sent for all agencies");
    }
    else if(numberOfSuccessRequests > 0 && numberOfSuccessRequests < listOfPickUpRequestsForAgencies.length){
        ShowNotification("warning", "bottomRight", "Pickup request partially");
    }
    else {
        ShowNotification("error", "bottomRight", "No Pickup request sent");
    }
    
    //now it is time to update the order status
    if(numberOfSuccessRequests > 0){
      return  await changeThePickUpReqeustStatus(successPickUpRequests);
    }
}


async function changeThePickUpReqeustStatus(successPickUpRequests){
    let result = await PickupRequest.updateOrderStatus(successPickUpRequests);
    
    return true;
}


function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  


async function login() {
    return LogisPickUp.login({
        email: LOGIS_USERNAME,
        password: LOGIS_PASSWORD
    });
}


async function getLocations(LOGIS_TOKEN){
    return LogisPickUp.getLocations(
        {
            "location": "",
            "token": LOGIS_TOKEN
        }
    );
}


async function createPickupRequest(data){
    return LogisPickUp.createPickupRequest(data);
}


function getCurrentUserLocation(agencyName){
    const locations =  JSON.parse(localStorage.getItem(LOGIS_LOCATION_LIST));
    // const currentUser =JSON.parse(localStorage.getItem(CURRENT_USER));
    let tempLocation = {};

    locations.forEach(location =>{
            if(location.name === agencyName){
                tempLocation = location;
            }
    });
   return tempLocation;
}
